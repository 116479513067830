import axios from 'axios'

import { REQUEST_TIMEOUT } from './settings.js'

const api = axios.create({
  baseURL: '/api/',
  timeout: REQUEST_TIMEOUT
})

/**
 * Send request
 * @param {FormData} body POST body
 */
export function postRequest (body) {
  return api.post('application/', body)
}

export function getApplicationSettings () {
  return api.get('application-settings/')
}

export function getApplicationExtraSettings () {
  return api.get('application-extra-settings/')
}
