<template>
  <v-root />
</template>

<script>
import VRoot from './views/VRoot.vue'

export default {
  name: 'App',

  components: {
    VRoot
  }
}
</script>
