<template>
  <div
    class="message"
    :class="`is-type-${type}`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CMessage',

  props: {
    type: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../css/variables.scss";

.message {
  padding: 0.375em 0.5em;

  color: variables.$color-gray-100;

  background: variables.$color-gray-700;
  border-radius: variables.$border-radius variables.$border-radius;

  &.is-type-error {
    color: variables.$color-red-100;
    background: variables.$color-red-700;
  };
  &.is-type-info {
    color: inherit;
    background: variables.$color-gray-200;
  }
}
</style>
