<script>
import { getApplicationSettings, getApplicationExtraSettings } from '../api'

import CContainer from '../components/CContainer.vue'
import CFooter from '../components/CFooter.vue'

export default {
  name: 'VRoot',

  components: {
    CContainer,
    CFooter
  },

  computed: {
    isFetchingSettings () {
      return this.$store.state.isFetchingSettings
    }
  },

  mounted () {
    this.$store.commit('isFetchingSettings', true)
    getApplicationExtraSettings()
      .then(response => {
        const result = response.data
        const textsInfo = {
          introText: '',
          pauseText: '',
          thanksText: ''
        }
        if (result) {
          textsInfo.introText = result.live_intro_text
          textsInfo.pauseText = result.pause_intro_text
          textsInfo.thanksText = result.thanks_text
          this.$store.commit('texts', textsInfo)
        }
      })
    getApplicationSettings()
      .then(response => {
        const result = response.data
        const publishedInfo = {
          published: false,
          isPausedByDate: false,
          publishAt: null,
          unpublishAt: null,
          readablePublishAt: '',
          readableUnpublishAt: ''
        }
        if (result.publish_at !== null && result.unpublish_at !== null) {
          const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
          const timeOptions = { hour: '2-digit', minute: '2-digit' }

          publishedInfo.publishAt = new Date(result.publish_at)
          const publishDate = publishedInfo.publishAt.toLocaleDateString('de-DE', dateOptions)
          const publishTime = publishedInfo.publishAt.toLocaleTimeString('de-DE', timeOptions)
          publishedInfo.readablePublishAt = publishDate + ', ' + publishTime

          publishedInfo.unpublishAt = new Date(result.unpublish_at)
          const unpublishDate = publishedInfo.unpublishAt.toLocaleDateString('de-DE', dateOptions)
          const unpublishTime = publishedInfo.unpublishAt.toLocaleTimeString('de-DE', timeOptions)
          publishedInfo.readableUnpublishAt = unpublishDate + ', ' + unpublishTime
        }
        if (result.is_paused) {
          this.$store.commit('published', publishedInfo)
        } else if (result.is_paused_by_date) {
          publishedInfo.isPausedByDate = true
          this.$store.commit('published', publishedInfo)
        } else {
          publishedInfo.published = true
          this.$store.commit('published', publishedInfo)
        }
        this.$store.commit('isFetchingSettings', false)
      })
      .catch(() => {
        this.$store.commit('isFetchingSettings', false)
        this.$store.commit('errors', true)
        if (this.$route.name !== 'home') {
          this.$router.push({ name: 'home' })
        }
      })
  }
}
</script>

<template>
  <c-container>
    <main>
      <div>
        <router-view />
      </div>
    </main>
    <c-footer v-if="!isFetchingSettings" />
  </c-container>
</template>
