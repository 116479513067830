<script>
import CLogo from '../components/CLogo.vue'
import CTitleSmall from '../components/CTitleSmall.vue'
import CMessage from '../components/CMessage.vue'

export default {
  name: 'CHomeClosed',

  components: {
    CLogo,
    CTitleSmall,
    CMessage
  },

  computed: {
    today: () => new Date(),
    publishAt () {
      return this.$store.state.publishedInfo.publishAt
    },
    unpublishAt () {
      return this.$store.state.publishedInfo.unpublishAt
    },
    readablePublishAt () {
      return this.$store.state.publishedInfo.readablePublishAt
    },
    readableUnpublishAt () {
      return this.$store.state.publishedInfo.readableUnpublishAt
    },
    isPausedByDate () {
      return this.$store.state.publishedInfo.isPausedByDate
    },
    pause_intro_text () {
      return this.$store.state.textsInfo.pauseText
    }
  }
}
</script>
<template>
  <div>
    <c-logo />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="pause_intro_text"
      v-html="pause_intro_text"
    />
    <!--eslint-enable-->
    <div v-else>
      <c-title-small>
        Projektförderung der Hamburgischen Kulturstiftung
      </c-title-small>
      <p class="mt-8 mb-8">
        Bitte lesen Sie vor dem Ausfüllen des Antragsformulars unbedingt die
        <a
          href="https://www.kulturstiftung-hh.de/antragstellung.html"
          target="_blank"
        >Hinweise zur Antragstellung</a>
        bei der Hamburgischen Kulturstiftung. Dort finden Sie wichtige Informationen
        zur Projektförderung sowie detaillierte Angaben, welche Unterlagen Sie für die
        Antragstellung bereithalten sollten.
      </p>
      <p>
        Bitte beachten Sie folgende Hinweise:
      </p>
      <br>
      <p>
        <ul>
          <li>
            Sie können Ihren Antrag nicht zwischenspeichern.
          </li>
          <li>
            Die Anträge werden in der Form bearbeitet, wie sie eingehen. Eine nachträgliche Korrektur oder Ergänzung ist nicht möglich.
          </li>
          <li>
            Daher empfehlen wir Ihnen, alle erforderlichen Informationen vorher vorzubereiten und für die Antragstellung bereitzuhalten.
          </li>
          <li>
            Dieses Antragsformular ist ausschließlich für die Projektförderung der Hamburgischen Kulturstiftung. Hier finden Sie Informationen zur Antragstellung für
            <a
              href="https://www.kulturstiftung-hh.de/was-wir-foerdern/art-connects.html"
              target="_blank"
            >ART CONNECTS</a>
            und
            <a
              href="https://www.kulturstiftung-hh.de/was-wir-foerdern/freiraeume.html"
              target="_blank"
            >FREIRÄUME!</a>.
          </li>
        </ul>
      </p>
      <br>
      <p>
        Das Antragsformular wird jeweils ca. sechs Wochen vor der Antragsfrist freigeschaltet. In der
        Zwischenzeit ist das Antragsformular in einer Testansicht verfügbar und das Einreichen von
        Anträgen nicht möglich.
      </p>
    </div>
    <br>

    <c-message
      type="error"
      class="errors mb-8"
    >
      <p class="mb-3">
        Das Formular kann aktuell nicht ausgefüllt und abgeschickt werden.
        <template v-if="isPausedByDate ">
          <span> Dies </span>
          <span v-if="publishAt > today">ist </span>
          <span v-else-if="unpublishAt < today">war </span>
          im Zeitraum {{ readablePublishAt }} Uhr bis {{ readableUnpublishAt }} Uhr möglich.
        </template>
      </p>
      <router-link
        :to="{ name: 'request' }"
        class="link"
      >
        <span>Sie können sich das Formular ansehen</span>
      </router-link>
      <span class="mb-3">, jedoch nicht absenden.</span>
    </c-message>
  </div>
</template>

<style lang="scss" scoped>
@use "../css/variables.scss";

.link {
  color: variables.$color-red-100;
}
</style>
