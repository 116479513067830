import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/**
 * Filter keys from being persisted
 * @param {string} key Object key
 * @param {*} value The stuff to stringify
 * @returns {null|*} The value or nothing
 */
function filterPersisting (key, value) {
  // Don't try to write files to localStorage. This doesn't end well.
  if (value instanceof File) {
    return ''
  }

  return value
}

const state = {
  steps: [],
  errors: {},
  publishedInfo: {},
  textsInfo: {},
  isFetchingSettings: false
}

/** @type {import("vuex").MutationTree<state>} */

const mutations = {
  step (state, { step, id, completed, fields }) {
    // Prepare object if step doesn't already exist.
    if (!state.steps[step]) {
      const stepTemplate = { id: null, completed: false, fields: {} }
      Vue.set(state.steps, step, stepTemplate)
    }

    // Fill stuff in.
    state.steps[step].id = id
    state.steps[step].completed = completed ?? false

    if (fields) {
      state.steps[step].fields = fields
    }

    // Create backup in localStorage.
    // Nobody wants large forms to suddenly disappear.
    localStorage.setItem(id, JSON.stringify(fields, filterPersisting))
  },

  errors (state, data) {
    state.errors = data
  },

  reset (state) {
    state.steps = []
    state.errors = {}
    localStorage.clear()
  },

  published (state, { published, publishAt, unpublishAt, readablePublishAt, readableUnpublishAt, isPausedByDate }) {
    state.publishedInfo = {
      published: published,
      isPausedByDate: isPausedByDate,
      publishAt: publishAt,
      unpublishAt: unpublishAt,
      readablePublishAt: readablePublishAt,
      readableUnpublishAt: readableUnpublishAt
    }
  },

  texts (state, { introText, pauseText, thanksText }) {
    state.textsInfo = {
      introText: introText,
      pauseText: pauseText,
      thanksText: thanksText
    }
  },

  isFetchingSettings (state, isFetching) {
    state.isFetchingSettings = isFetching
  }
}

export default new Vuex.Store({ state, mutations })
