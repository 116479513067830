<template>
  <component
    :is="tag"
    class="button"
    :class="`is-color-${color}`"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'CButton',

  inheritAttrs: false,

  props: {
    tag: {
      type: String,
      default: 'button',
      validator: value => [
        'button',
        'router-link'
      ].includes(value)
    },
    color: {
      type: String,
      default: 'primary',
      validator: value => [
        'primary',
        'secondary'
      ].includes(value)
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../css/variables.scss";

.button {
  display: inline-block;
  padding: 0.5em 0.75em;

  font-weight: 700;
  line-height: 1.2;
  text-decoration: none;
  color: #fff;

  border-radius: variables.$border-radius;

  transition: background-color 0.15s;

  &.is-color-primary {
    background: variables.$color-primary;

    &:hover {
      background: variables.$color-primary-dark;
    }
  }

  &.is-color-secondary {
    background: variables.$color-gray-500;

    &:hover {
      background: variables.$color-gray-600;
    }
  }
}
</style>
