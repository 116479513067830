import Bowser from 'bowser'
import Vue from 'vue'

import App from './App.vue'

import router from './router.js'
import store from './store.js'

import './main.scss'

const browser = Bowser.getParser(navigator.userAgent)

const browserIsSupported = browser.satisfies({
  chrome: '>=75',
  edge: '>=15',
  firefox: '>=70',
  ie: '>=11',
  opera: '>=60',
  safari: '>=10',
  samsung_internet: '>=10.1'
})

if (!browserIsSupported) {
  document.querySelector('#unsupported').removeAttribute('hidden')
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
