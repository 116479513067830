<script>
import CHomeOpen from '../components/CHomeOpen.vue'
import CHomeClosed from '../components/CHomeClosed.vue'
import CMessage from '../components/CMessage.vue'
import CLoading from '../components/CLoading.vue'

export default {
  name: 'VHome',
  components: {
    CHomeOpen,
    CHomeClosed,
    CMessage,
    CLoading
  },

  computed: {
    published () {
      return this.$store.state.publishedInfo.published
    },
    settingsError () {
      return this.$store.state.errors === true
    },
    isFetchingSettings () {
      return this.$store.state.isFetchingSettings
    }
  }
}

</script>

<template>
  <div>
    <c-loading
      v-if="isFetchingSettings"
      message="Das Formular wird geladen"
    />
    <c-home-open v-else-if="!isFetchingSettings && published" />
    <c-home-closed v-else />
    <c-message
      v-if="settingsError"
      type="error"
      class="mb-8"
    >
      <div>
        <p class="mb-3">
          Beim Laden der Antragsstrecke kam es zu einem Fehler. Bitte probieren Sie es zu einem späteren Zeitpunkt erneut.
        </p>
        <p class="mb-3">
          Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an uns.
        </p>
      </div>
    </c-message>
  </div>
</template>
