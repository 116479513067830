<template>
  <footer class="footer">
    <p class="mb-4">
      <a
        href="https://www.kulturstiftung-hh.de/impressum.html"
        target="_blank"
      >Impressum <span aria-label="(neuer Tab)">↗</span></a>
    </p>
    <p class="mb-4">
      <a
        href="https://www.kulturstiftung-hh.de/impressum.html"
        target="_blank"
      >Datenschutzerklärung <span aria-label="(neuer Tab)">↗</span></a>
    </p>
    <p>
      Entwickelt in <span title="und Buchholz">Hamburg</span> von
      <a
        href="https://lfda.de"
        target="_blank"
        class="third-party-link"
      >LfdA – Labor für digitale Angelegenheiten <span aria-label="(neuer Tab)">↗</span></a>.
    </p>
  </footer>
</template>

<script>
export default {
  name: 'CFooter'
}
</script>

<style lang="scss" scoped>
@use "../css/variables.scss";

.footer {
  margin-top: 4em;

  font-size: 0.9375em;
  color: variables.$color-gray-600;
}

.third-party-link {
  color: inherit;
}
</style>
