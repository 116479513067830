<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CContainer'
}
</script>

<style lang="scss" scoped>
@use "../css/variables.scss";

.container {
  width: variables.$container-width;
  max-width: 100%;
  margin: 4em auto;
  padding: 0 variables.$container-padding;
}
</style>
